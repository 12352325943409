<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333" v-if="foottype == 3">隐私政策</div>
      <div class="font-20 text-333333" v-if="foottype == 4">版权申明</div>
      <div class="font-20 text-333333" v-if="foottype == 5">商务洽谈</div>
      <div class="font-20 text-333333" v-if="foottype == 6">推广加盟</div>
      <div class="font-20 text-333333" v-if="foottype == 7">战略合作</div>
      <div class="font-20 text-333333" v-if="foottype == 8">创作联盟</div>
      <div class="font-20 text-333333" v-if="foottype == 9">版本详情</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/set' }" v-if="foottype == 9"
            >设置</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="foottype == 3">隐私政策</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 4">版权申明</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 5">商务洽谈</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 6">推广加盟</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 7">战略合作</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 8">创作联盟</el-breadcrumb-item>
          <el-breadcrumb-item v-if="foottype == 9">版本详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-30">
      <div v-html="info"></div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      info: "",
      foottype: 1,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let foottype = this.$route.query.foottype;
        if (foottype) {
          this.foottype = foottype;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let foottype = this.$route.query.foottype;
    if (foottype) {
      this.foottype = foottype;
      this.getdata();
    }
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("index/content", {
          type: that.foottype,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data = res.data
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
          that.info = res.data;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
